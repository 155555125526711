import type { CustomOptionsProps } from '../../types';

export const customOptions: CustomOptionsProps = {
  languageCode: 'it',
  regionISO: 'IT',
  cookieManagementEnabled: true,
  googleAdsEnabled: false,
  headerLiftEnabled: true,
  googleSearchConsoleId: '',
  googleTagManagerCode: 'GTM-W3J7Z24',
  googleAnalyticsId: ' G-6TEPXPNKTG',
  facebookTrackingId: '',
  twitterHandle: '@gamedistribution',
  twitterSiteHandle: '@azerion',
  appIconId: '2b395754-da2f-4518-9fd8-facb575ef4e7',
  headerLogoId: 'bfcfe260-a5d7-4755-a6f4-4ffb11356190',
  footerLogoId: 'a87ed213-b7a1-4c09-aac3-3f08febb9d32',
  fontUrl: 'https://fonts.googleapis.com/css2?family=Quicksand&family=Poppins&display=swap',
  defaultTitle: 'Libero',
  titleTemplate: 'or-site-f75dx3oq9fy | %s',
  noFollow: true,
  noIndex: true,
  cookieLawProId: '',
  extraHeaderURL: '',
  extraFooterURL: '',
  extraHeaderStyleURL: '',
  extraFooterStyleURL: '',
  customCss: ``,
  scripts: [
    {
      id: 'idhb', // Required by HeaderLift.
      src: 'https://hb.improvedigital.com/pbw/headerlift.min.js',
      strategy: 'afterInteractive'
    },
    {
      id: 'gtm-script', // Required when using dangerouslySetInnerHTML.
      strategy: 'afterInteractive',
      dangerouslySetInnerHTML: {
        __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', 'GTM-W3J7Z24');
          `
      }
    },
    {
      // Todo: Nunjucks the GA tracking IDs.
      src: `https://www.googletagmanager.com/gtag/js?id= G-6TEPXPNKTG`,
      strategy: 'afterInteractive'
    },
    {
      id: 'gtag-datalayer-script', // Required when using dangerouslySetInnerHTML.
      strategy: 'afterInteractive',
      dangerouslySetInnerHTML: {
        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', ' G-6TEPXPNKTG', {
              page_path: window.location.pathname,
            });
          `
      }
    },
    {
      id: 'iol-script',
      src: 'https://dsh7ky7308k4b.cloudfront.net/publishers/liberoit.min.js',
      strategy: 'lazyOnload',
      dangerouslySetInnerHTML: {
        __html: `if (window.IOL) {
  window.iat = new IOL.Analytics.Tracking.Engine(); window.iat.send();
}
`
      },
    },
    {
      src: 'https://pm.azerioncircle.com/p/focus/',
      strategy: 'afterInteractive',
    },
    {
      src: 'https://i.plug.it/cmp/iub_conf_libero.js',
      strategy: 'beforeInteractive',
    },
    {
      src: 'https://cdn.iubenda.com/cs/tcf/stub-v2.js',
      strategy: 'beforeInteractive',
    },
    {
      src: 'https://cdn.iubenda.com/cs/iubenda_cs.js',
      strategy: 'afterInteractive',
    },
    {
      src: 'https://i.plug.it/cmp/qciol.min.js',
      strategy: 'afterInteractive',
    },
    {
      src: 'https://i.plug.it/iplug/js/lib/iol/analytics/data/fun-libero-it/tracking_fun-libero-it.min.js',
      strategy: 'afterInteractive',
    },
    {
      src: 'https://i.plug.it/iplug/js/lib/iol/analytics/engine/IOL.Analytics.Tracking.min.js',
      strategy: 'afterInteractive',
    },
    {
      id: 'iol-script',
      strategy: 'lazyOnload',
    },
  ],
  links: [
  ],
};